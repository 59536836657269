
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "TheHeader",
  setup() {
    const router = useRouter();
    const store = useStore();
    const logout = async () => {
      await store.dispatch("logout");
      await router.push({ name: "auth.login" });
    };

    return {
      logout,
    };
  },
});
