
import { defineComponent } from "vue";
import TheHeader from "@/components/common/TheHeader.vue";

export default defineComponent({
  name: "MainLayout",
  components: {
    TheHeader,
  },
});
